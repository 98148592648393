export default {
  transportation: 'النقل',
  process: 'الرحلات',
  companies: 'الشركات',
  drivers: 'السائقين',
  offDrivers: 'السائقين الغير مفعلين',
  offCompanies: 'الشركات الغير مفعلة',
  vehiclesType: 'أقسام الشاحنات',
  vehiclesMake: 'أنواع الشاحنات',
  vehicles: 'الشاحنات',
  companySettings: 'إعدادات الشركة',
  driversSettings: 'إعدادات السائق'
}
