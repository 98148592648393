// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const processes = () => import('./views/processes')
const companies = () => import('./views/companies')
const offCompanies = () => import('./views/offCompanies')
const addTransportation = () => import('./views/addTransportation')
const drivers = () => import('./views/drivers')
const offDrivers = () => import('./views/offDrivers')
const vehiclesTypes = () => import('./views/vehiclesTypes')
const addVehicleType = () => import('./components/addVehicleType')
const vehiclesMake = () => import('./views/vehiclesMake')
const addVehicleMake = () => import('./components/addVehicleMake')
const vehicles = () => import('./views/vehicles')
const companiesSettings = () => import('./views/companiesSettings')
const driversSettings = () => import('./views/driversSettings')
const addRequired = () => import('./components/addRequired')
// start Routes
export default [
  {
    path: '/transportation',
    name: 'processes',
    component: dashboardLayout,
    children: [{
      path: 'processes',
      name: 'processes',
      meta: {
        name: 'processes',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: processes
    }, {
      path: 'add-transportation',
      name: 'addTransportation',
      meta: {
        name: 'addTransportation',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addTransportation
    },
    {
      path: 'companies',
      name: 'companies',
      meta: {
        name: 'companies',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: companies
    },
    {
      path: 'off-companies',
      name: 'offCompanies',
      meta: {
        name: 'offCompanies',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: offCompanies
    },
    {
      path: 'drivers',
      name: 'drivers',
      meta: {
        name: 'drivers',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: drivers
    },
    {
      path: 'off-drivers',
      name: 'offDrivers',
      meta: {
        name: 'offDrivers',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: offDrivers
    },
    {
      path: 'vehicles-types',
      name: 'vehiclesTypes',
      meta: {
        name: 'vehiclesTypes',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: vehiclesTypes
    },
    {
      path: 'add-vehicle-type',
      name: 'addVehicleType',
      meta: {
        name: 'addVehicleType',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addVehicleType
    },
    {
      path: 'edit-vehicle-type/:id',
      name: 'editVehicleType',
      meta: {
        name: 'editVehicleType',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addVehicleType
    },
    {
      path: 'view-vehicle-type/:id',
      name: 'viewVehicleType',
      meta: {
        name: 'viewVehicleType',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addVehicleType
    },
    {
      path: 'vehicles-make',
      name: 'vehiclesMake',
      meta: {
        name: 'vehiclesMake',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: vehiclesMake
    },
    {
      path: 'add-vehicle-make',
      name: 'addVehicleMake',
      meta: {
        name: 'addVehicleMake',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addVehicleMake
    },
    {
      path: 'edit-vehicle-make/:id',
      name: 'editVehicleMake',
      meta: {
        name: 'editVehicleMake',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addVehicleMake
    },
    {
      path: 'view-vehicle-make/:id',
      name: 'viewVehicleMake',
      meta: {
        name: 'viewVehicleMake',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addVehicleMake
    },
    {
      path: 'vehicles',
      name: 'vehicles',
      meta: {
        name: 'vehicles',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: vehicles
    },
    {
      path: 'companies-settings',
      name: 'companiesSettings',
      meta: {
        name: 'companiesSettings',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: companiesSettings
    },
    {
      path: 'drivers-settings',
      name: 'driversSettings',
      meta: {
        name: 'driversSettings',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: driversSettings
    },
    {
      path: 'add-required',
      name: 'addRequired',
      meta: {
        name: 'addRequired',
        parentName: 'transportation',
        userType: '',
        permission: ''
      },
      component: addRequired
    }
    ]
  }
]
