// Layout
const dashboardLayout = () => import('@/layouts/DashboardLayout/dashboardLayout.vue')

const books = () => import('./views/index')
const hospitals = () => import('./views/hospitals')
const doctors = () => import('./views/doctors/index')
const addDoctor = () => import('./views/doctors/addDoctor')
const financialOperations = () => import('./views/financialOperations')
const documents = () => import('./views/documents')
const viewDoctorDocuments = () => import('./views/viewDoctorDocuments')
const inActiveDoctors = () => import('./views/inActiveDoctors')
const inActiveClinics = () => import('./views/inActiveHospital')
const settings = () => import('./views/settings')

// start Routes
export default [
  {
    path: '/veterianian',
    name: 'veterianian',
    component: dashboardLayout,
    children: [
      {
        path: 'books',
        name: 'books',
        meta: {
          name: 'books',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: books
      },
      {
        path: 'hospitals',
        name: 'hospitals',
        meta: {
          name: 'hospitals',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: hospitals
      },
      {
        path: 'doctors',
        name: 'doctors',
        meta: {
          name: 'doctors',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: doctors
      }, {
        path: 'add-doctor',
        name: 'addDoctor',
        meta: {
          name: 'addDoctor',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: addDoctor
      }, {
        path: 'financialOperations',
        name: 'financialOperations',
        meta: {
          name: 'financialOperations',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: financialOperations
      }, {
        path: 'documents',
        name: 'documents',
        meta: {
          name: 'documents',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: documents
      }, {
        path: 'view-doctor-documents/:id',
        name: 'viewDoctorDocuments',
        meta: {
          name: 'viewDoctorDocuments',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: viewDoctorDocuments
      },
      {
        path: 'inactive-doctors',
        name: 'inActiveDoctors',
        meta: {
          name: 'inActiveDoctors',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: inActiveDoctors
      },
      {
        path: 'inactive-clinics',
        name: 'inActiveClinics',
        meta: {
          name: 'inActiveClinics',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: inActiveClinics
      },
      {
        path: 'veteSettings',
        name: 'veteSettings',
        meta: {
          name: 'veteSettings',
          parentName: 'veterianian',
          userType: '',
          permission: ''
        },
        component: settings
      }
    ]
  }
]
